@charset 'UTF-8';
@import "/Users/quanbui/website/vhost/tokai-koukoku/src/scss/utility/_media-queries.scss";
@import "/Users/quanbui/website/vhost/tokai-koukoku/src/scss/utility/_mixin.scss";

.mv__img {
	.item.active {
		opacity: 1;
	}
	.item--02 {
		.thumb {
			left: 14.38%;
			top: 0;
			opacity: 0;
			transform: scale(0.7);
			.img {
				&:before {
					padding-top: 79.81651%;
				}
			}
		}
	}
	.item--03 {
		.thumb {
			&:nth-child(1) {
				top: 25.29%;
				left: 0;
				opacity: 0;
				transform: translateY(200px) translateX(-50px);
			}
			&:nth-child(3) {
				top: 25.29%;
				top: 25.29%;
				left: 14.38%;
				opacity: 0;
				transform: scale(0.7);
			}
			&:nth-child(7) {
				top: 25.29%;
				right: 14.38%;
				opacity: 0;
				transform: translate(100px, 100px);
			}
			&:nth-child(5) {
				top: 50.57%;
				right: 28.75%;
				opacity: 0;
				transform: scale(0.7);
			}
			&:nth-child(8) {
				top: 50.57%;
				right: 14.38%;
				opacity: 0;
				transform: translate(100px, 100px);
				transform-origin: bottom right;
				opacity: 0;
				transform: scale(0.8);
			}
			&:nth-child(10) {
				top: 50.57%;
				right: 0;
				opacity: 0;
				transform: translate(100px, 100px);
			}
			&:nth-child(2) {
				top: 0;
				left: 28.75%;
				opacity: 0;
				transform: translateY(250px);
			}
			&:nth-child(4) {
				transform-origin: bottom right;
				top: 0;
				left: 43.13%;
				opacity: 0;
				transform: scale(0.8);
			}
			&:nth-child(6) {
				right: 28.75%;
				bottom: 0;
				opacity: 0;
				transform: translate(-100px, 100px);
			}
			&:nth-child(9) {
				right: 0;
				top: 0;
				opacity: 0;
				transform: translateY(250px);
			}
		}
	}
	.item--04 {
		.thumb {
			&:nth-child(1) {
				top: 0;
				right: 28.75%;
				opacity: 0;
				transform: scale(0.5);
				transform-origin: bottom left;
			}
			&:nth-child(3) {
				top: 0;
				right: 0;
				opacity: 0;
				transform: translate(150px, 150px);
			}
			&:nth-child(2) {
				right: 28.75%;
				top: 25.29%;
				opacity: 0;
				transform: translateY(100px);
				.img {
					&:before {
						padding-top: 74.71264%;
					}
				}
			}
			&:nth-child(4) {
				right: 14.38%;
				top: 50.57%;
				opacity: 0;
				transform: translateY(-300px);
			}
		}
	}
}
@media (min-width: 768px) {
	.mv__img {
		.item.active.item--01 {
			.thumb {
				&:nth-child(1) {
					animation: slide01_thumb01 0.5s 0.1s ease-in forwards;
				}
				&:nth-child(2) {
					animation: slide01_thumb02 0.4s 0.2s ease-in forwards;
				}
				&:nth-child(3) {
					animation: slide01_thumb03 0.6s 0s ease-in forwards;
				}
				&:nth-child(4) {
					animation: slide01_thumb04 0.6s 0.1s ease-in forwards;
				}
				&:nth-child(5) {
					animation: slide01_thumb05 0.6s 0.2s ease-in forwards;
				}
				&:nth-child(6) {
					animation: slide01_thumb06 0.6s 0s ease-in forwards;
				}
				&:nth-child(7) {
					animation: slide01_thumb07 0.6s 0.1s ease-in forwards;
				}
			}
		}
		.item.active.item--02 {
			.thumb {
				animation: slide02_thumb 0.7s 0s ease-in forwards;
			}
		}
		.item.active.item--03 {
			.thumb {
				&:nth-child(1) {
					animation: slide03_thumb1 0.6s 0.2s ease forwards;
				}
				&:nth-child(2) {
					animation: slide03_thumb2 0.6s 0s ease forwards;
				}
				&:nth-child(3) {
					animation: slide03_thumb3 0.6s 0.2s ease forwards;
				}
				&:nth-child(4) {
					animation: slide03_thumb04 0.6s 0.2s ease forwards;
				}
				&:nth-child(5) {
					animation: slide03_thumb2 0.6s 0.2s ease forwards;
				}
				&:nth-child(6) {
					animation: slide03_thumb6 0.6s 0.2s ease forwards;
				}
				&:nth-child(7) {
					animation: slide03_thumb10 0.6s 0.3s ease forwards;
				}
				&:nth-child(8) {
					animation: slide03_thumb3 0.6s 0.3s ease forwards;
				}
				&:nth-child(9) {
					animation: slide03_thumb9 0.6s 0.2s ease forwards;
				}
				&:nth-child(10) {
					animation: slide03_thumb10 0.6s 0.2s ease forwards;
				}
			}
		}
		.item.active.item--04 {
			.thumb {
				&:nth-child(1) {
					animation: slide04_thumb1 0.6s 0.1s ease forwards;
				}
				&:nth-child(2) {
					animation: slide04_thumb2 0.4s 0s ease forwards;
				}
				&:nth-child(3) {
					animation: slide04_thumb3 0.6s 0.1s ease forwards;
				}
				&:nth-child(4) {
					animation: slide04_thumb4 0.6s 0s ease forwards;
				}
			}
		}
		.item--01 {
			.thumb {
				&:nth-child(1) {
					left: 14.38%;
					top: 0;
					opacity: 0;
					transform: translateY(-180px) translateX(-180px);
				}
				&:nth-child(2) {
					left: 57.51%;
					top: 25.29%;
					opacity: 0;
					transform: scale(0.9);
				}
				&:nth-child(3) {
					left: 57.51%;
					top: 50.57%;
					opacity: 0;
					transform: translateY(80px) translateX(80px);
				}
				&:nth-child(4) {
					left: 57.51%;
					top: 75.86%;
					opacity: 0;
					transform: translateY(-80px) translateX(-80px);
				}
				&:nth-child(5) {
					right: 0;
					top: 0;
					opacity: 0;
					transform: translateY(-100px) translateX(100px);
				}
				&:nth-child(7) {
					right: 0;
					top: 50.57%;
					opacity: 0;
					transform: translateY(-250px) translateX(-250px);
				}
				&:nth-child(6) {
					top: 50.57%;
					right: 14.38%;
					opacity: 0;
					transform: scale(0.9);
				}
			}
		}
	}
}
@media (max-width: 767px) {
	.mv__img {
		.item--01 {
			.thumb {
				&:nth-child(1) {
					width: 74.64%;
					top: 0;
					left: 0;
				}
				&:not(:nth-child(1)) {
					width: 24.1%;
					right: 0;
				}
				&:nth-child(4) {
					bottom: 0;
				}
				&:nth-child(3) {
					bottom: 25.36%;
				}
				&:nth-child(2) {
					bottom: 50.72%;
				}
			}
		}
	}
}
